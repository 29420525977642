<template>
  <div class="my_class">
    <div class="warpper">
      <div class="summary flex-layout">
        <div class="search">
          <el-autocomplete
            popper-class="my-autocomplete"
            v-model="state"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            @select="handleSelect"
          >
            <i
              class="el-icon-search el-input__icon"
              slot="suffix"
              @click="handleIconClick"
            >
            </i>
            <template slot-scope="{ item }">
              <div class="name">{{ item.value }}</div>
              <span class="addr">{{ item.address }}</span>
            </template>
          </el-autocomplete>
        </div>
        <div class="gender">
          <el-select v-model="gender" placeholder="性别">
            <el-option
              v-for="item in genderList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="role">
          <el-select v-model="role" placeholder="角色">
            <el-option
              v-for="item in roleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="table-section">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            fixed
            prop="name"
            label="姓名"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="number"
            label="编号"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="gender" label="性别" width="50" align="center">
          </el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="city" label="地区" width="100" align="center">
          </el-table-column>
          <el-table-column
            prop="school"
            label="学校"
            width="300"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="grade" label="年级" width="120" align="center">
          </el-table-column>
          <el-table-column prop="class" label="班级" width="80" align="center">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <!-- <el-button type="text" size="small">编辑</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      genderList: [
        {
          value: "选项1",
          label: "男"
        },
        {
          value: "选项2",
          label: "女"
        }
      ],
      roleList: [
        {
          value: "选项1",
          label: "老师"
        },
        {
          value: "选项2",
          label: "专家"
        },
        {
          value: "选项3",
          label: "学生"
        },
        {
          value: "选项4",
          label: "助教"
        },
        {
          value: "选项5",
          label: "领导"
        }
      ],
      gender: "",
      role: "",
      estaurants: [],
      state: "",
      tableData: [
        // {
        //   date: "2016-05-02",
        //   name: "王小虎",
        //   number: 1161656,
        //   gender: "男",
        //   phone: "135****5858",
        //   username: 2341563,
        //   province: "上海",
        //   city: "普陀区",
        //   school: "金凤区第三小学",
        //   grade: "三年级",
        //   class: "五班"
        // },
        // {
        //   date: "2016-05-04",
        //   name: "王小虎",
        //   province: "上海",
        //   city: "普陀区",
        //   address: "上海市普陀区金沙江路 1517 弄",
        //   zip: 200333
        // },
        // {
        //   date: "2016-05-01",
        //   name: "王小虎",
        //   province: "上海",
        //   city: "普陀区",
        //   address: "上海市普陀区金沙江路 1519 弄",
        //   zip: 200333
        // },
        // {
        //   date: "2016-05-03",
        //   name: "王小虎",
        //   province: "上海",
        //   city: "普陀区",
        //   address: "上海市普陀区金沙江路 1516 弄",
        //   zip: 200333
        // },
        // {
        //   date: "2016-05-03",
        //   name: "王小虎",
        //   province: "上海",
        //   city: "普陀区",
        //   address: "上海市普陀区金沙江路 1516 弄",
        //   zip: 200333
        // }
      ]
    };
  },
  watch: {},
  computed: {},
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      }
    },
    handleSelect(item) {
      // console.log(item);
    },
    handleIconClick(ev) {
      // console.log(ev);
    },
    handleClick(row) {
      // console.log(row);
    }
  },
  mounted() {}
};
</script>

<style lang="less">
.my_class {
  align-self: flex-start;
  border-radius: 10px;
  // box-shadow: 0px 5px 23px 0px rgba(22, 115, 255, 0.1);
  max-width: 819px;
  width: 819px;

  background: #ffffff;

  .warpper {
    padding: 33px 15px 73px;
  }

  .summary {
    & > div {
      margin-right: 15px;
    }
  }

  .my-autocomplete {
    li {
      line-height: normal;
      padding: 7px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .addr {
        font-size: 12px;
        color: #b4b4b4;
      }

      .highlighted .addr {
        color: #ddd;
      }
    }
  }

  .table-section {
    margin-top: 30px;
  }

  .el-table {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .el-table__header {
    thead th {
      color: #fff;
      background-color: rgba(35, 115, 236, 0.76);
      border: 0;
    }
  }
}

@media screen and (max-width: 1250px) {
  .my_class {
    max-width: 660px;
    width: 660px;
  }
}
</style>
